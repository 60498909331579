var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, rounded: "sm" } },
        [
          _c("div", { staticClass: "info-header mt-2 mb-2" }, [
            _vm._v(_vm._s(_vm.$t("NAME"))),
          ]),
          _c("b-form-input", {
            attrs: {
              trim: "",
              placeholder: _vm.$t("NAME"),
              state: _vm.stateName,
            },
            on: {
              keypress: function ($event) {
                return _vm.isLettersAndNumbers($event)
              },
            },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
          _vm.name.length > 256
            ? _c("div", { staticClass: "mt-2 error-text" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("ERROR.TOO_MANY_CHARACTERS")) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          !_vm.isValidText
            ? _c("div", { staticClass: "mt-2 error-text" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("ERROR.CHARACTERS_NOT_ALLOWED")) +
                    " " +
                    _vm._s(_vm.notAllowedCharacters) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "text-right" }, [
            _c("small", { staticClass: "pull-right" }, [
              _vm._v(_vm._s(_vm.name.length) + "/256"),
            ]),
          ]),
          _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
          _c(
            "b-button",
            { attrs: { id: "cancelBtn" }, on: { click: _vm.cancelBtn } },
            [_vm._v(_vm._s(_vm.$t("CLOSE")))]
          ),
          _c(
            "b-button",
            {
              staticClass: "btn-fill",
              attrs: {
                disabled: _vm.addDisabled,
                variant: "primary",
                id: "addBtn",
              },
              on: { click: _vm.addBtn },
            },
            [_vm._v(_vm._s(_vm.$t("SAVE")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }