var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, rounded: "sm" } },
        [
          _c(
            "form",
            { staticClass: "mt-2" },
            [
              _c(
                "FileSelect",
                {
                  staticClass: "mt-2",
                  attrs: { "show-drop": "", "max-size": "200" },
                  model: {
                    value: _vm.files,
                    callback: function ($$v) {
                      _vm.files = $$v
                    },
                    expression: "files",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "no-border",
                      attrs: { size: "sm", variant: "secondary" },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.files.length) + "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.files.length != 0
            ? _c(
                "span",
                { key: "attachments_" + _vm.files.length },
                _vm._l(_vm.files, function (item, index) {
                  return _c(
                    "span",
                    { key: index, staticClass: "ms-fontSize-12 mt-2" },
                    [
                      _c(
                        "span",
                        { class: _vm.filesClass(item) },
                        [
                          _c("Attachment", {
                            attrs: {
                              "is-error": _vm.checkError(index),
                              percent: _vm.checkPercent(index),
                              item: item,
                              index: index,
                            },
                            on: {
                              removeFile: function ($event) {
                                return _vm.removeFile(index)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          !_vm.AllValidFilesize
            ? _c("div", { staticClass: "error-text" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("ERROR.LARGE_FILES")) +
                    " " +
                    _vm._s(
                      this.GetMaxTotalBytesAllowedToSendFilestorage + "MB"
                    ) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          !_vm.AllValidNames
            ? _c("div", { staticClass: "error-text" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("ERROR.TOO_MANY_CHARACTERS")) +
                    " " +
                    _vm._s(256) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          !_vm.allValidText
            ? _c("div", { staticClass: "error-text" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("ERROR.CHARACTERS_NOT_ALLOWED")) +
                    " " +
                    _vm._s(_vm.notAllowedCharacters) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
          _c(
            "b-button",
            { attrs: { id: "cancelBtn" }, on: { click: _vm.cancelBtn } },
            [_vm._v(_vm._s(_vm.$t("CLOSE")))]
          ),
          _c(
            "b-button",
            {
              staticClass: "btn-fill",
              attrs: {
                disabled: _vm.addDisabled,
                id: "addBtn",
                variant: "primary",
              },
              on: { click: _vm.addBtn },
            },
            [_vm._v(_vm._s(_vm.$t("SAVE")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }