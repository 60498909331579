var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user.information && _vm.loaded
    ? _c(
        "section",
        [
          !_vm.filestorage_enabled
            ? _c("div", [
                _c("div", { staticClass: "center-information" }, [
                  _c("div", { staticClass: "center-information-content" }, [
                    _c("i", { staticClass: "fa-light fa-2x fa-file-slash" }),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("NO_FILESTORAGE_SERVICE")) +
                        "\n      "
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.filestorage_enabled
            ? _c(
                "div",
                {
                  key: _vm.$route.fullPath,
                  class: { showFolders: _vm.showFolders },
                  attrs: { id: "wrapper" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "h-100", attrs: { id: "inbox-sidebar" } },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.showFolders,
                            expression: "!showFolders",
                          },
                        ],
                        staticClass: "fal fa-chevron-right nav-icon clickable",
                        on: {
                          click: function ($event) {
                            _vm.showFolders = true
                          },
                        },
                      }),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showFolders,
                            expression: "showFolders",
                          },
                        ],
                        staticClass: "fal fa-chevron-left nav-icon clickable",
                        on: {
                          click: function ($event) {
                            _vm.showFolders = false
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.showFolders,
                              expression: "!showFolders",
                            },
                          ],
                          staticClass: "mt-2 smallFolders",
                        },
                        [
                          _c(
                            "b-nav",
                            {
                              staticClass: "mt-2 inbox-nav",
                              attrs: { vertical: "", tabs: "" },
                            },
                            [
                              _c(
                                "b-nav-text",
                                [
                                  _c("b-avatar", { attrs: { size: "30px" } }, [
                                    _c("i", { staticClass: "fal fa-user-alt" }),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "b-nav-item",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  attrs: {
                                    title: _vm.$t("FILESTORAGE.FILES"),
                                    to: {
                                      name: "FileStorage.Files",
                                      params: {
                                        parent_uuid: null,
                                        section: "FILES",
                                      },
                                    },
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changedFolder("")
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "fal fa-folders" }),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v(_vm._s(_vm.$t("FILESTORAGE.FILES"))),
                                  ]),
                                ]
                              ),
                              _vm._l(_vm.applications, function (item, index) {
                                return _c(
                                  "b-nav-item",
                                  { key: "application_" + index },
                                  [
                                    _vm._v(
                                      "\n            v-b-tooltip.hover\n              :to=\"{\n                name: 'FileStorage.Application',\n                params: { folder_uuid: item.folder_uuid, section: 'APPLICATION' },\n              }\"\n              @click=\"changedFolder('')\"\n            >\n              "
                                    ),
                                    _c("i", { staticClass: "fal fa-folders" }),
                                    _vm._v(
                                      "  " +
                                        _vm._s(item.name) +
                                        "               \n        "
                                    ),
                                    item.not_listed != 0
                                      ? _c("b-badge", [
                                          _vm._v(_vm._s(item.not_listed)),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showFolders,
                              expression: "showFolders",
                            },
                          ],
                          staticClass: "mt-2",
                        },
                        [
                          _c(
                            "b-nav",
                            {
                              staticClass: "mt-2 inbox-nav",
                              attrs: { vertical: "", tabs: "" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "email-text" },
                                [
                                  _c(
                                    "b-nav-text",
                                    { staticClass: "mb-0 pb-0" },
                                    [
                                      _vm._v("\n               "),
                                      _c(
                                        "b-avatar",
                                        {
                                          staticClass: "mb-1",
                                          attrs: {
                                            size: "30px",
                                            variant: "secondary",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fal fa-folders",
                                          }),
                                        ]
                                      ),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.user.information.email) +
                                          "\n            "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "email-text" },
                                [
                                  _c(
                                    "b-nav-item",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          modifiers: { hover: true },
                                        },
                                      ],
                                      attrs: {
                                        to: {
                                          name: "FileStorage.Files",
                                          params: {
                                            folder_uuid: null,
                                            section: "FILES",
                                          },
                                        },
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changedFolder("")
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fal fa-folders",
                                      }),
                                      _vm._v(
                                        "  " +
                                          _vm._s(_vm.$t("FILESTORAGE.FILES")) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._l(_vm.applications, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "application_" + index,
                                    staticClass: "email-text",
                                  },
                                  [
                                    _c(
                                      "b-nav-item",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            modifiers: { hover: true },
                                          },
                                        ],
                                        attrs: {
                                          to: {
                                            name: "FileStorage.Application",
                                            params: {
                                              folder_uuid: item.folder_uuid,
                                              section: "APPLICATION",
                                            },
                                          },
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changedFolder("")
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fal fa-folders",
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(item.name) +
                                            "           \n              "
                                        ),
                                        item.not_listed != 0
                                          ? _c("b-badge", [
                                              _vm._v(_vm._s(item.not_listed)),
                                            ])
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { attrs: { id: "inbox-content" } }, [
                    _c(
                      "div",
                      { staticClass: "w-100" },
                      [
                        _c(
                          "b-form",
                          {
                            key: "folder_uuid_" + _vm.folder_uuid,
                            attrs: { inline: "" },
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "no-border",
                                on: { click: _vm.refreshList },
                              },
                              [
                                _c("i", { staticClass: "fal fa-sync" }),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v(_vm._s(_vm.$t("REFRESH"))),
                                ]),
                              ]
                            ),
                            _vm.section == "FILES"
                              ? _c(
                                  "b-dropdown",
                                  {
                                    attrs: { variant: "dark" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "button-content",
                                          fn: function () {
                                            return [
                                              _c("i", {
                                                staticClass: "fal fa-plus",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("ADD")) +
                                                  "\n            "
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      445137253
                                    ),
                                  },
                                  [
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: {
                                          title: _vm.$t(
                                            "FILESTORAGE.ADD_FOLDER"
                                          ),
                                          href: "#",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.show_add_folder = true
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fal fa-folder-plus",
                                        }),
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t("FILESTORAGE.ADD_FOLDER")
                                            )
                                        ),
                                      ]
                                    ),
                                    _vm.folder_uuid
                                      ? _c(
                                          "b-dropdown-item",
                                          {
                                            attrs: {
                                              title: _vm.$t(
                                                "FILESTORAGE.ADD_NOTE"
                                              ),
                                              href: "#",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.show_add_note = true
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fal fa-file-alt",
                                            }),
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t("FILESTORAGE.ADD_NOTE")
                                                )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.folder_uuid
                                      ? _c(
                                          "b-dropdown-item",
                                          {
                                            attrs: {
                                              title: _vm.$t(
                                                "FILESTORAGE.ADD_FILES"
                                              ),
                                              href: "#",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.show_add_file = true
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fal fa-file-plus",
                                            }),
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "FILESTORAGE.ADD_FILES"
                                                  )
                                                )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "label",
                              {
                                staticClass: "sr-only",
                                attrs: { for: "inline-form-input-search" },
                              },
                              [_vm._v(_vm._s(_vm.$t("LIMIT")))]
                            ),
                            _c("b-form-input", {
                              staticClass:
                                "i-100 searchText hidden-mobile ml-2",
                              attrs: {
                                trim: "",
                                id: "inline-form-input-search",
                                placeholder: _vm.$t("SEARCH"),
                              },
                              on: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  $event.preventDefault()
                                },
                              },
                              model: {
                                value: _vm.searchText,
                                callback: function ($$v) {
                                  _vm.searchText = $$v
                                },
                                expression: "searchText",
                              },
                            }),
                            _vm.folder_uuid &&
                            _vm.IsAdmin &&
                            _vm.section == "FILES"
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "no-border ml-2",
                                    on: {
                                      click: function ($event) {
                                        return _vm.folderUpdate(_vm.folder_uuid)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fal fa-cog" })]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "hidden-not-mobile w-100 mt-2 ml-2",
                              },
                              [
                                _c(
                                  "b-form",
                                  { attrs: { inline: "" } },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "sr-only",
                                        attrs: {
                                          for: "inline-form-input-search",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("SEARCH")))]
                                    ),
                                    _c("b-form-input", {
                                      staticClass: "ml-2 i-100 searchText",
                                      attrs: {
                                        trim: "",
                                        placeholder: _vm.$t("SEARCH"),
                                      },
                                      on: {
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          $event.preventDefault()
                                        },
                                      },
                                      model: {
                                        value: _vm.searchText,
                                        callback: function ($$v) {
                                          _vm.searchText = $$v
                                        },
                                        expression: "searchText",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("router-view", {
                          key: _vm.redrawListKey,
                          attrs: {
                            searchText: _vm.searchText,
                            parent_uuid: _vm.folder_uuid,
                            user_uuid: _vm.user_uuid,
                            limit: _vm.limit,
                          },
                          on: {
                            "update:searchText": function ($event) {
                              _vm.searchText = $event
                            },
                            "update:search-text": function ($event) {
                              _vm.searchText = $event
                            },
                            "update:limit": function ($event) {
                              _vm.limit = $event
                            },
                            changeSection: _vm.changeSection,
                            fileInfo: _vm.fileInfo,
                            fileUpdate: _vm.fileUpdate,
                            changedFolder: _vm.changedFolder,
                            folderUpdate: _vm.folderUpdate,
                            folderUsers: _vm.folderUsers,
                            folderMove: _vm.folderMove,
                            fileNewVersion: _vm.fileNewVersion,
                            fileVersions: _vm.fileVersions,
                            fileMove: _vm.fileMove,
                            fileSendAs: _vm.fileSendAs,
                            setCrumbs: _vm.setCrumbs,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "b-sidebar",
            {
              attrs: {
                id: "update-folder-sidebar",
                title: _vm.$t("FILESTORAGE.UPDATE_FOLDER"),
                "no-header-close": "",
                "sidebar-class": "big_sidebar",
                lazy: "",
                right: "",
                backdrop: "",
                "no-slide": "",
                shadow: "",
              },
              model: {
                value: _vm.show_update_folder,
                callback: function ($$v) {
                  _vm.show_update_folder = $$v
                },
                expression: "show_update_folder",
              },
            },
            [
              _vm.selected_folder_uuid
                ? _c(
                    "div",
                    { staticClass: "view-content create-message px-3 py-2" },
                    [
                      _c("UpdateFolder", {
                        attrs: {
                          user_uuid: _vm.user_uuid,
                          start_tab: _vm.updateFolderTab,
                          folder_uuid: _vm.selected_folder_uuid,
                        },
                        on: {
                          updatedInfo: _vm.updatedInfo,
                          removedFolder: _vm.removedFolder,
                          closeUpdateFolder: _vm.closeUpdateFolder,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "b-sidebar",
            {
              attrs: {
                id: "add-folder-sidebar",
                title: _vm.$t("FILESTORAGE.ADD_FOLDER"),
                "no-header-close": "",
                "sidebar-class": "big_sidebar",
                right: "",
                lazy: "",
                backdrop: "",
                "no-slide": "",
                shadow: "",
              },
              model: {
                value: _vm.show_add_folder,
                callback: function ($$v) {
                  _vm.show_add_folder = $$v
                },
                expression: "show_add_folder",
              },
            },
            [
              _c(
                "div",
                { staticClass: "view-content create-message px-3 py-2" },
                [
                  _c("AddFolder", {
                    attrs: {
                      folder_uuid: _vm.folder_uuid,
                      user_uuid: _vm.user_uuid,
                    },
                    on: {
                      addedFolder: _vm.addedFolder,
                      closeAddFolder: _vm.closeAddFolder,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-sidebar",
            {
              attrs: {
                id: "add-file-sidebar",
                title: _vm.$t("FILESTORAGE.ADD_FILES"),
                "no-header-close": "",
                "sidebar-class": "big_sidebar",
                right: "",
                lazy: "",
                backdrop: "",
                "no-slide": "",
                shadow: "",
              },
              model: {
                value: _vm.show_add_file,
                callback: function ($$v) {
                  _vm.show_add_file = $$v
                },
                expression: "show_add_file",
              },
            },
            [
              _c(
                "div",
                { staticClass: "view-content create-message px-3 py-2" },
                [
                  _c("AddFile", {
                    attrs: {
                      folder_uuid: _vm.folder_uuid,
                      user_uuid: _vm.user_uuid,
                    },
                    on: {
                      addedFile: _vm.addedFile,
                      closeAddFile: _vm.closeAddFile,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-sidebar",
            {
              attrs: {
                id: "update-file-sidebar",
                title: _vm.$t("FILESTORAGE.UPDATE_FILE"),
                "no-header-close": "",
                "sidebar-class": "big_sidebar",
                right: "",
                lazy: "",
                backdrop: "",
                "no-slide": "",
                shadow: "",
              },
              model: {
                value: _vm.show_update_file,
                callback: function ($$v) {
                  _vm.show_update_file = $$v
                },
                expression: "show_update_file",
              },
            },
            [
              _c(
                "div",
                { staticClass: "view-content create-message px-3 py-2" },
                [
                  _c("UpdateFile", {
                    attrs: {
                      section: _vm.section,
                      folder_uuid: _vm.folder_uuid,
                      start_tab: _vm.updateFileTab,
                      file_uuid: _vm.selected_file_uuid,
                      user_uuid: _vm.user_uuid,
                    },
                    on: {
                      updatedInfo: _vm.updatedInfo,
                      removedFile: _vm.removedFile,
                      closeUpdateFile: _vm.closeUpdateFile,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-sidebar",
            {
              attrs: {
                id: "add-note-sidebar",
                title: _vm.$t("FILESTORAGE.ADD_NOTE"),
                "no-header-close": "",
                "sidebar-class": "big_sidebar",
                right: "",
                lazy: "",
                backdrop: "",
                "no-slide": "",
                shadow: "",
              },
              model: {
                value: _vm.show_add_note,
                callback: function ($$v) {
                  _vm.show_add_note = $$v
                },
                expression: "show_add_note",
              },
            },
            [
              _c(
                "div",
                { staticClass: "view-content create-message px-3 py-2" },
                [
                  _c("AddNote", {
                    attrs: {
                      folder_uuid: _vm.folder_uuid,
                      user_uuid: _vm.user_uuid,
                    },
                    on: {
                      addedNote: _vm.addedNote,
                      closeAddNote: _vm.closeAddNote,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-modal",
            {
              attrs: {
                lazy: "",
                "ok-variant": "secondary",
                "ok-only": "",
                "ok-title": _vm.$t("CLOSE"),
                "hide-header": "",
                id: "modal-send-as-message",
              },
              model: {
                value: _vm.show_send_message,
                callback: function ($$v) {
                  _vm.show_send_message = $$v
                },
                expression: "show_send_message",
              },
            },
            [
              _vm.files.length != 0
                ? _c("SendAsMessage", {
                    attrs: { files: _vm.files },
                    on: { SentMessage: _vm.SentMessage },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }