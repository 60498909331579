var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.files
    ? _c(
        "section",
        [
          _c(
            "b-list-group",
            { staticClass: "mt-2" },
            _vm._l(_vm.sendAttachments, function (item, index) {
              return _c("b-list-group-item", { key: index }, [
                _c("i", { staticClass: "fal fa-file" }),
                _vm._v(" " + _vm._s(item.name) + " "),
              ])
            }),
            1
          ),
          _c("div", { staticClass: "info-header mt-2" }, [
            _vm._v(_vm._s(_vm.$t("FROM"))),
          ]),
          _c("b-form-select", {
            staticClass: "mt-2",
            attrs: { name: "selected_from", options: _vm.from_list },
            model: {
              value: _vm.selected_from,
              callback: function ($$v) {
                _vm.selected_from = $$v
              },
              expression: "selected_from",
            },
          }),
          _c("div", { staticClass: "mt-2 ml-2" }, [
            _c("img", {
              staticClass: "icon",
              attrs: {
                src: "/img/message_type/message.png",
                alt: _vm.$t("MESSAGES.TYPE.message"),
              },
            }),
            _vm._v(
              " " +
                _vm._s(_vm.boxInformation.name) +
                ", " +
                _vm._s(_vm.boxInformation.email) +
                "\n      "
            ),
            _vm.boxInformation.fax
              ? _c("span", [
                  _vm._v(", "),
                  _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src: "/img/message_type/fax-message.png",
                      alt: _vm.$t("MESSAGES.TYPE.fax-message"),
                    },
                  }),
                  _vm._v(" " + _vm._s(_vm.boxInformation.fax)),
                ])
              : _vm._e(),
            _vm.boxInformation.sdk
              ? _c("span", [
                  _vm._v(", "),
                  _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src: "/img/message_type/sdk-message.png",
                      alt: _vm.$t("MESSAGES.TYPE.sdk-message"),
                    },
                  }),
                  _vm._v(" " + _vm._s(_vm.boxInformation.sdk)),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "info-header mt-2" }, [
            _vm._v(_vm._s(_vm.$t("SEND_AS"))),
          ]),
          _c(
            "b-list-group",
            [
              _c(
                "b-list-group-item",
                { attrs: { href: "#" }, on: { click: _vm.showSefos } },
                [
                  _c("img", {
                    staticClass: "icon mr-2",
                    attrs: {
                      src: "/img/message_type/message.png",
                      alt: _vm.$t("MESSAGES.TYPE.message"),
                    },
                  }),
                  _vm._v(" " + _vm._s(_vm.$t("MESSAGES.TYPE.message"))),
                ]
              ),
              _vm.sdkVisible
                ? _c(
                    "b-list-group-item",
                    { attrs: { href: "#" }, on: { click: _vm.showSDK } },
                    [
                      _c("img", {
                        staticClass: "icon mr-2",
                        attrs: {
                          src: "/img/message_type/sdk-message.png",
                          alt: _vm.$t("MESSAGES.TYPE.sdk-message"),
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("MESSAGES.TYPE.sdk-message")) +
                          "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.faxVisible
                ? _c(
                    "b-list-group-item",
                    {
                      attrs: {
                        href: "#",
                        disabled: _vm.onlyPdfAllowed || _vm.files.length > 1,
                      },
                      on: { click: _vm.showFax },
                    },
                    [
                      _c("img", {
                        staticClass: "icon mr-2",
                        attrs: {
                          src: "/img/message_type/fax-message.png",
                          alt: _vm.$t("MESSAGES.TYPE.fax-message"),
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("MESSAGES.TYPE.fax-message")) +
                          "\n      "
                      ),
                      _vm.onlyPdfAllowed
                        ? _c("div", { staticClass: "error-text" }, [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("ERROR.ONLY_PDF_ALLOWED")) +
                                "\n      "
                            ),
                          ])
                        : _vm._e(),
                      _vm.files.length > 1
                        ? _c("div", { staticClass: "error-text" }, [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("ERROR.ONLY_ONE_ATTACHMENT")) +
                                "\n      "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("AddSefosMessage", {
            attrs: {
              show: _vm.showSefosMessage,
              user_uuid: _vm.selected_from.user_uuid,
              functionbox_uuid: _vm.selected_from.functionbox_uuid,
              attachments: _vm.sendAttachments,
            },
            on: {
              ChangeShow: _vm.changeShowSefosMessage,
              SentMessage: _vm.SentMessage,
            },
          }),
          _vm.sdkVisible
            ? _c("AddSDKMessage", {
                attrs: {
                  show: _vm.showSdkMessage,
                  functionbox_uuid: _vm.selected_from.functionbox_uuid,
                  attachments: _vm.sendAttachments,
                },
                on: {
                  ChangeShow: _vm.changeShowSdkMessage,
                  SentMessage: _vm.SentMessage,
                },
              })
            : _vm._e(),
          _c("AddFaxMessage", {
            attrs: {
              show: _vm.showFaxMessage,
              attachments: _vm.sendAttachments,
              functionbox_uuid: _vm.selected_from.functionbox_uuid,
            },
            on: {
              ChangeShow: _vm.changeShowFaxMessage,
              SentMessage: _vm.SentMessage,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }