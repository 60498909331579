<template>
  <section class="mt-2" v-if="information">
    <b-tabs>
      <b-tab :title="$t('ACCESS')" active @click="cancelAdd">
        <b-form inline class="mt-2">
          <b-form-input trim
            v-model="searchText"
            :placeholder="$t('SEARCH')"
            @keydown.enter.prevent
            class="searchText newline-mobile ml-0"
          ></b-form-input>
        </b-form>

        <b-table
          small
          class="mt-2"
          :busy="loading"
          bordered
          :items="currentItems"
          hover
          :fields="fields"
          :tbody-tr-class="rowClass"
          @row-clicked="clickedRow"
        >
          <template #head(name)>
            <b-form-checkbox
              v-if="currentItems.length != 0"
              v-model="remove_all"
              @change="removeAll"
            ></b-form-checkbox>
            {{ $t("NAME") }}
          </template>

          <template #head(id)>
            <b-form-checkbox
              v-if="currentItems.length != 0"
              v-model="remove_all"
              @change="removeAll"
            ></b-form-checkbox>
            &nbsp;
          </template>

          <template v-slot:cell(expire_date)="data">
            <span v-if="data.item.expire_date == '0001-01-01T00:00:00'">
              {{$t('NEVER')}}
            </span>
            <span v-if="data.item.expire_date != '0001-01-01T00:00:00'">
              {{ FormatDateTime(data.item.expire_date) }}
            </span>
          </template>


          <template v-slot:cell(name)="data">
            <b-form inline>
              <b-form-checkbox
                v-model="RemoveFolderAccessDTO.user_uuids"
                :value="data.item.user_uuid"
              ></b-form-checkbox>
              <b-img
                class="profileImage"
                src="/img/default-avatar.png"
                :alt="data.item.name"
                rounded="circle"
              ></b-img>
              {{ data.item.name }}
            </b-form>
          </template>

          <template v-slot:cell(email)="data">{{ data.item.email }}</template>

          <template v-slot:cell(is_admin)="data">{{
            showAdmin(data.item.is_admin)
          }}</template>

          <template v-slot:cell(id)="data">
            <b-form inline>
              <b-form-checkbox
                v-model="RemoveFolderAccessDTO.user_uuids"
                :value="data.item.user_uuid"
              ></b-form-checkbox>
              <UserInfo
                image="/img/default-avatar.png"
                :primary="data.item.name"
                :secondary="data.item.email"
              ></UserInfo>
            </b-form>
          </template>

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
        </b-table>

        <b-pagination
          class="mt-2"
          v-model="currentPage"
          :total-rows="totalCurrentItems"
          :per-page="limit"
          align="center"
          pills
          @input="getCurrentUsers"
        >
        </b-pagination>

        <hr class="p-0 m-0 mt-2 mb-2" />

        <b-button @click="closeBtn">{{ $t("CLOSE") }}</b-button>

        <b-button
          :disabled="!validRemove"
          variant="danger"
          class="btn-fill"
          @click="removeBtn"
          >{{ $t("REMOVE") }}</b-button
        >
      </b-tab>

      <b-tab :title="$t('ADD')" @click="showAddBtn">
        <b-form inline class="mt-2">
          <b-form-input trim
            v-model="searchText"
            :placeholder="$t('SEARCH')"
            @keydown.enter.prevent
            class="searchText newline-mobile ml-0"
          ></b-form-input>
        </b-form>

        <b-table
          small
          class="mt-2"
          :busy="loading"
          bordered
          :items="items"
          :fields="fieldsFree"
          @row-clicked="clickedRow"
        >
          <template #head(name)>
            <b-form-checkbox
              v-if="items.length != 0"
              v-model="add_all"
              @change="addAll"
            ></b-form-checkbox>
            {{ $t("NAME") }}
          </template>

          <template #head(id)>
            <b-form-checkbox
              v-if="items.length != 0"
              v-model="add_all"
              @change="addAll"
            ></b-form-checkbox>
            &nbsp;
          </template>

          <template v-slot:cell(name)="data">
            <b-form inline>
              <b-form-checkbox
                v-model="AddFolderAccessRequestDTO.user_uuids"
                :value="data.item.user_uuid"
              ></b-form-checkbox>
              <b-img
                class="profileImage"
                src="/img/default-avatar.png"
                :alt="data.item.name"
                rounded="circle"
              ></b-img>
              {{ data.item.name }}
            </b-form>
          </template>

          <template v-slot:cell(email)="data">{{ data.item.email }}</template>

          <template v-slot:cell(is_admin)="data">{{
            showAdmin(data.item.is_admin)
          }}</template>

          <template v-slot:cell(id)="data">
            <b-form inline>
              <b-form-checkbox
                v-model="AddFolderAccessRequestDTO.user_uuids"
                :value="data.item.user_uuid"
              ></b-form-checkbox>

              <UserInfo
                image="/img/default-avatar.png"
                :primary="data.item.name"
                :secondary="data.item.email"
              ></UserInfo>
            </b-form>
          </template>

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
        </b-table>

        <b-pagination
          class="mt-2"
          v-model="usersPage"
          :total-rows="totalItems"
          :per-page="limit"
          align="center"
          pills
          @input="getUsers"
        >
        </b-pagination>

        <hr class="p-0 m-0 mt-2 mb-2" />

        <b-button @click="closeBtn">{{ $t("CLOSE") }}</b-button>

        <b-button
          :disabled="!valid"
          variant="primary"
          class="btn-fill"
          v-b-modal.add-delegated-settings
          >{{ $t("ADD") }}</b-button
        >
      </b-tab>
    </b-tabs>

    <b-modal
      id="add-delegated-settings"
      hide-header
      @cancel="cancelSave"
      @ok="okSave"
      :ok-disabled="!validExpireTime"
      centered
      :cancel-title="$t('CANCEL')"
      :ok-title="$t('ADD')"
    >
      {{ $t("EXPIRES") }}

        <b-form-checkbox
          id="checkbox-1"
          class="mt-2 mb-2"
          v-model="not_expired"
          name="checkbox-1"
          :value="true"
          switch
          :unchecked-value="false"
        >
          {{ $t("FILESTORAGE.DOES_NOT_EXPIRE") }}
        </b-form-checkbox>

        <PickDate
        v-model="AddFolderAccessRequestDTO.expire_date"
        use-clock
        @valid="(n) => (validExpireTime = n)"></PickDate>

        
      <b-form-checkbox
        id="checkbox-is-admin"
        class="mt-2"
        v-model="AddFolderAccessRequestDTO.is_admin"
        name="checkbox-is-admin"
        :value="1"
        switch
        :unchecked-value="0"
      >
        {{ $t("FILESTORAGE.IS_ADMIN") }}
      </b-form-checkbox>
    </b-modal>
  </section>
</template>
<script>
import PickDate from "../../Input/PickDate";
import UserInfo from "../../Layout/UserInfo";
export default {
  components: {
    UserInfo,
    PickDate
  },
  props: ["user_uuid", "information"],
  data() {
    return {
      validExpireTime: true,
      remove_all: false,
      add_all: false,
      addNewUsers: false,
      loading: false,
      show_sidebar: false,
      searchText: "",
      limit: 10,
      currentPage: 1,
      usersPage: 1,
      not_expired: true,
      currentItems: [],
      totalCurrentItems: 0,
      items: [],
      totalItems: 0,
      user_uuids: [],
      fields: [
        {
          key: "id",
          class: "hidden-not-mobile",
          thClass: "",
        },
        {
          key: "name",
          label: this.$t("NAME"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "expire_date",
          label: this.$t("EXPIRES"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "email",
          label: this.$t("EMAIL"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "is_admin",
          label: this.$t("ADMINLEVEL.TITLE"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
      ],
      fieldsFree: [
        {
          key: "id",
          class: "hidden-not-mobile",
          thClass: "",
        },
        {
          key: "name",
          label: this.$t("NAME"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "email",
          label: this.$t("EMAIL"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "is_admin",
          label: this.$t("ADMINLEVEL.TITLE"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
      ],
      AddFolderAccessRequestDTO: {
        is_admin: 0,
        expire_date: null,
        user_uuid: this.user_uuid,
        folder_uuid: this.information.folder_uuid,
        user_uuids: [],
      },
      RemoveFolderAccessDTO: {
        folder_uuid: this.information.folder_uuid,
        user_uuids: [],
      },
    };
  },
  methods: {
    closeBtn() {
      this.$emit("close");
    },
    rowClass(item, type) {
      if (item && type === "row") {
        let returnClass = "";
        if(item.expired)
        {
          returnClass = "expired";
        } 
        return returnClass;
      }
      return "";
    },
    async okSave() {
      this.loading = true;
      let self = this;
      this.$http
        .post(
          this.user.hostname + "/filestorage/folder/share/add",
          this.AddFolderAccessRequestDTO
        )
        .then(() => {
          self.getCurrentUsers();
          self.getUsers();
          self.AddFolderAccessRequestDTO.user_uuids = [];
          self.expire_date = null;
          self.AddFolderAccessRequestDTO.is_admin = 0;
          self.addNewUsers = false;
          self.loading = false;
          self.showAdd = false;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    cancelSave() {
      this.expire_date = null;
      this.AddFolderAccessRequestDTO.is_admin = 0;
    },
    async init() {
      /*this.AddFolderAccessRequestDTO.expire_date = new this.$luxonDateTime.utc()
        .set({
          seconds: 0,
          milliseconds: 0,
        })
        .plus({ days: 1 })
        .toISO();*/
      this.AddFolderAccessRequestDTO.expire_date = null;
      this.getCurrentUsers();
    },
    clickedRow(item) {
      if (this.addNewUsers) {
        if (this.AddFolderAccessRequestDTO.user_uuids.includes(item.user_uuid)) {
          let indexOf = this.AddFolderAccessRequestDTO.user_uuids.indexOf(
            item.user_uuid
          );
          this.AddFolderAccessRequestDTO.user_uuids.splice(indexOf, 1);
        } else {
          this.AddFolderAccessRequestDTO.user_uuids.push(item.user_uuid);
        }
      } else {
        if (
          this.RemoveFolderAccessDTO.user_uuids.includes(item.user_uuid)
        ) {
          let indexOf = this.RemoveFolderAccessDTO.user_uuids.indexOf(
            item.user_uuid
          );
          this.RemoveFolderAccessDTO.user_uuids.splice(indexOf, 1);
        } else {
          this.RemoveFolderAccessDTO.user_uuids.push(item.user_uuid);
        }
      }
    },
    removeAll: function () {
      if (this.remove_all) {
        this.RemoveFolderAccessDTO.user_uuids = this.currentItems.map(
          (a) => a.user_uuid
        );
      } else {
        this.RemoveFolderAccessDTO.user_uuids = [];
      }
    },
    addAll: function () {
      if (this.add_all) {
        this.AddFolderAccessRequestDTO.user_uuids = this.items.map(
          (a) => a.user_uuid
        );
      } else {
        this.AddFolderAccessRequestDTO.user_uuids = [];
      }
    },
    cancelAdd: function () {
      this.remove_all = false;
      this.getUsers();
      this.addNewUsers = false;
      this.getCurrentUsers();
    },
    showAddBtn: function () {
      this.add_all = false;
      this.getCurrentUsers();
      this.addNewUsers = true;
      this.getUsers();
    },
    getCurrentUsers: function () {
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/filestorage/folder/share/users", {
          ShowHasAccess: true,
          page: this.currentPage,
          user_uuid: this.user_uuid,
          folder_uuid: this.information.folder_uuid,
          limit: parseInt(this.limit),
          search_text: this.searchText,
        })
        .then((response) => {
          self.loading = false;
          self.currentItems = response.data.items;
          self.totalCurrentItems = response.data.count;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    getUsers: function () {
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/filestorage/folder/share/users", {
          ShowHasAccess: false,
          page: this.usersPage,
          folder_uuid: this.information.folder_uuid,
          user_uuid: this.user_uuid,
          limit: parseInt(this.limit),
          search_text: this.searchText,
        })
        .then((response) => {
          self.loading = false;
          self.items = response.data.items;
          self.totalItems = response.data.count;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    showAdmin: function (is_admin) {
      return is_admin == 1 ? this.$t("YES") : this.$t("NO");
    },
    removeBtn: function () {
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          dialogClass: this.teams.theme,
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.loading = true;
            self.$http
              .post(
                self.user.hostname + "/filestorage/folder/share/remove",
                self.RemoveFolderAccessDTO
              )
              .then(() => {
                self.getCurrentUsers();
                self.getUsers();
                self.RemoveFolderAccessDTO.user_uuids = [];
                self.loading = false;
                self.showAdd = false;
              })
              .catch(() => {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
  },
  computed: {
    valid: function () {
      return this.AddFolderAccessRequestDTO.user_uuids.length != 0;
    },
    validRemove: function () {
      return this.RemoveFolderAccessDTO.user_uuids.length != 0;
    },
  },
  watch: {
    searchText: function () {
      if (this.searchText.length == 0) {
        this.getUsers();
        this.getCurrentUsers();
      }
      if (this.searchText.length >= 2) {
        this.getUsers();
        this.getCurrentUsers();
      }
    },
    limit: function () {
      this.getUsers();
      this.getCurrentUsers();
    },
    not_expired: function (newVal) {
      if(newVal == true)
      {
        this.AddFolderAccessRequestDTO.expire_date = null;
      }else{
        this.AddFolderAccessRequestDTO.expire_date = new this.$luxonDateTime.utc()
        .set({
          seconds: 0,
          milliseconds: 0,
        })
        .plus({ days: 1 })
        .toISO();
      }
    }
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>