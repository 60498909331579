<template>
  <section>
    <b-overlay :show="loading" rounded="sm">
      <div class="info-header mt-2">{{ $t("NAME") }}</div>

      <b-form-input trim :placeholder="$t('NAME')" v-model="name" :state="stateName"  v-on:keypress="isLettersAndNumbers($event)"></b-form-input>

      <div class="mt-2 error-text" v-if="!isValidText">
        {{ $t("ERROR.CHARACTERS_NOT_ALLOWED") }} {{  notAllowedCharacters }}
      </div>

      <div class="mt-2 error-text" v-if="name.length > maxLengthName">
        {{ $t("ERROR.TOO_MANY_CHARACTERS") }}
      </div>
      <div class="text-right">
        <small class="pull-right">{{ name.length }}/{{maxLengthName}}</small>
      </div>


      <b-form-textarea
        class="mt-2 textarea"
        id="messageContent"
        input-id="message"
        ref="messageContent"
        v-model="content"
        autocomplete="off"
        :placeholder="$t('CONTENT')"
        rows="15"
        :state="content.length <= maxLengthContent"
        max-rows="15"
      ></b-form-textarea>
      <div class="mt-2 error-text" v-if="content.length > maxLengthContent">
        {{ $t("ERROR.TOO_MANY_CHARACTERS") }}
      </div>
      <div class="text-right">
        <small class="pull-right">{{ content.length }}/{{maxLengthContent}}</small>
      </div>

      <hr class="p-0 m-0 mt-2 mb-2" />

      <b-button @click="cancelBtn">{{ $t("CLOSE") }}</b-button>

      <b-button
        :disabled="addDisabled"
        @click="addBtn"
        id="addBtn"
        variant="primary"
        class="btn-fill"
        >{{ $t("SAVE") }}</b-button
      >
    </b-overlay>
  </section>
</template>
<script>
//import * as blobUtil from "blob-util";
export default {
  components: {},
  props: {    
    folder_uuid: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    }
  },
  data() {
    return {
      maxLengthName: 256,
      maxLengthContent: 20000,
      name: "",
      content: "",
      loading: false,
    };
  },
  methods: {
    cancelBtn() {
      this.name = "";
      this.$emit("closeAddNote");
    },
    async addBtn() {
      this.loading = true;
      var dataBlob = new Blob([this.content], { type: "text/plain" });
      let file = new File([dataBlob], this.name + ".txt");
      let formData = new FormData();
      formData.append("file", file);
      formData.append("name", file.name);
      formData.append("folder_uuid", this.folder_uuid);
      formData.append("user_uuid", this.user_uuid);
      let self = this;
      this.$http
        .post(this.user.hostname + "/filestorage/file/add", formData)
        .then(() => {
          self.$emit("addedNote");
        })
        .catch(() => {
          self.loading = false;
        });
    },
  },
  computed: {
    addDisabled() {
      if(this.name == "")
      {
        return true;
      }
      if(this.name.length > this.maxLengthName)
      {
        return true;
      }
      if(this.content.length > this.maxLengthContent)
      {
        return true;
      }      
      if(this.name.includes(".")){
        return true;
      }
      if(!this.validateLettersAndNumbers(this.name))
      {
        return true;
      }
      return false;
    },
    isValidText(){
      return this.name == "" || this.validateLettersAndNumbers(this.name);
    },
    stateName(){
      if(this.name == "")
      {
        return null;
      }
      return !this.addDisabled;
    }  
  },
};
</script>
<style>
</style>
